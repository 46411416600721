import React, { useEffect } from "react";
import { resetCart } from "store/actions/cart.action";
import QRCode from "qrcode.react";
import { Helmet } from "react-helmet";
import { useConfig } from "hooks/use-config.hook";
import { translationHelper } from "helpers/translation.helper";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import Layout from "../components/layout.component";
import { TranslationKeys } from "../interfaces/translation-keys.enum";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../hooks/use-redux.hook";
import { CheckCircleIcon } from "@heroicons/react/solid";

interface ThankYouProps {
}

const ThankYou: React.FC<ThankYouProps> = (props) => {
    const config = useConfig();
    const dispatch = useAppDispatch();

    const { t, i18n } = useTranslation();

    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);

    const order_number = urlParams.get("order_number") || "";


    useEffect(() => {
        dispatch(resetCart());
    }, []);

    useEffect(() => {
        if (typeof window !== "undefined") {
            sessionStorage.removeItem("last_known_cart_id");
            const orders = localStorage.getItem("orders") || "[]";
            const parsedOrders = JSON.parse(orders).filter(
                (order: string) => order !== order_number,
            );
            parsedOrders.push(order_number);
            localStorage.setItem("orders", JSON.stringify(parsedOrders));
            sessionStorage.clear();
        }
    }, []);

    useEffect(() => {
        if (window.fbq) {
            window.fbq("track", "Purchase", {
                value: 0.0,
                currency: "EUR",
            });
        }
    }, [])

    return (
        <Layout>
            <Helmet>
                <title>{t(TranslationKeys.CART_THANK_YOU_TITLE)} - {translationHelper(config.title, i18n.language)}</title>
            </Helmet>
            <div
                className="w-full sm:rounded-md bg-white overflow-hidden sm:shadow py-2 flex justify-center content-center text-center">
                <div className="block">
                    <CheckCircleIcon className="w-20 h-20 text-green-600 inline-flex justify-center" />
                    <h1 className="text-xl mb-2">{t(TranslationKeys.CART_THANK_YOU_TITLE)}</h1>
                    <QRCode
                        renderAs="canvas"
                        value={order_number}
                        fgColor="#2C2E35"
                        includeMargin={false}
                        size={128}
                        className="m-auto mb-2"
                    />
                    <p className="mb-4">
                        {t(TranslationKeys.CART_THANK_YOU_ORDER_NUMBER, { order_number })}
                    </p>
                    {/*<div className="mb-4">
                        {ticket && (
                            <Button
                                color="primary"
                                type="button"
                                onClick={() =>
                                    navigate(
                                        "/stream/?ticket_code=" +
                                        ticket?.ticket_code
                                    )
                                }
                                fullWidth={true}
                            >
                                Siirry katsomaan
                            </Button>
                        )}
                    </div>*/}
                    {typeof window !== "undefined" &&
                    localStorage.getItem("current_url") ? (
                        <Link
                            className="btn btn-primary inline-block text-center"
                            to={localStorage.getItem("current_url") || "/"}
                        >
                            {t(TranslationKeys.COMMON_BACK_TO_EVENT)}
                        </Link>
                    ) : (
                        <Link
                            to="/"
                            className="btn btn-primary inline-block text-center"
                        >
                            {t(TranslationKeys.COMMON_BACK_TO_HOME)}
                        </Link>
                    )}
                </div>
            </div>
        </Layout>
    );
};

export default ThankYou;
